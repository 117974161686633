.more-about-us-container {
	margin-top: 106px;
	text-align: center;
	background: #F3F5FA;
	padding-bottom: 20px;

	.more-about-us-values {
		.more-about-us-title {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: bold;
			font-size: 55px;
			line-height: 38px;
			color: black !important;
			padding-bottom: 44px;
			text-align: center;
			padding-top: 80px;
		}

		.values-box {
			display: inline-flex;
			justify-content: center;
			margin: 10px;

				background: #FFFFFF;
			/* Sombra-CARD */
			box-shadow: 0px 29px 40px rgba(163, 163, 163, 0.25);
			border-radius: 20px;
			.values {
				padding: 60px;			


				img {
					width: 110px;
				}

				h1 {
					font-size: 30px;
					font-family: 'Raleway', sans-serif;
					margin-top: 10px;
				}
			}
		}


	}
}

@media screen and (max-width: 800px) {
	.more-about-us-container {
		.more-about-us-values {
			.values-box {
				.values {
					padding: 25px !important;

					img {
						width: 130px !important;
					}

					h1 {
						font-size: 25px !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 400px) {
	.more-about-us-container {
		.more-about-us-values {
			.values-box {
				.values {
					padding: 25px !important;

					img {
						width: 130px !important;
					}

					h1 {
						font-size: 25px !important;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1100px){
	.values-box2{
		width: 18%;
		height: 285px;
}
}

@media screen and (min-width: 1800px){
	.values-box2 {
		width: 13% !important;
		height: 285px !important;
	}
}

