
.principal{
	width: auto;
	margin: 0!important;
	height: auto;
	display: flex;
}
.content-ellipse{
	margin-top: 10px;
	height: auto;
	padding: 0%!important;
	position: relative;
	width: 100%;
	height: auto;
}
.ellipse{
	object-fit: contain;
	width: 100%;
	height: 55%;
	margin-left: -23%!important;
}
.content-monitor{
	margin-top: 43px;
	width: 27%;
	position:absolute;
}
.monitor{
	object-fit: contain;
	width: 70%;
}

.content-shoppin{
	position:fixed; 
	bottom:48%; 
	position:absolute;
	vertical-align: bottom;
	width: 30%;
}
.shoppin{
	object-fit: contain;
	width: 65%;
}
.content-smartphone{
	top: 18%;
	left : 33%;
	position:absolute;
	width: 30%;
}
.smartphone{
	object-fit: contain;
	width: 100%;
	margin: 0 auto;
}
.content-description-ellipse{
	position:absolute;
	top: 26%;
	left: 5%;
	max-width: 12%;
	font-weight: bold;
	color: black;
}

.app-content{

}
.app{
	object-fit: contain;
	margin-top: 70px!important;
	// border: solid gray 1px;
	width: 100%!important;
	margin: auto;
	border: 10px solid #E5E5E5;
	box-sizing: border-box;
	filter: drop-shadow(0px 4px 8px rgba(152, 193, 217, 0.78));
	border-radius: 20px;
}
.app-option{
	justify-content: center;
	margin: auto;
	width: 100%;
	padding: 4px;

}
.option{
	border-radius: 10px!important;
	padding: 15px!important;
	// background-color: #E0E0E0!important;
	// box-shadow: 4px 6px 10px rgba(54, 126, 142, 0.35)!important;
	font-size: 25px!important;
	color: black!important;
	text-transform: none!important;
}
.ask1{
	margin-top: 30px!important;
	margin: auto;
	width: 100%;
}
.one{
	text-align: center;
	color: black;
	font-weight: bold;
	font-size: 36px;
}

@media screen and (max-width: 574px) {
	.content-ellipse {
		max-height: 600px;
		width: 100%;
		
	}
	.ellipse{
		object-fit: contain;
		width: 100%!important;
		padding: 0px;
		height: 100%;
	}
	.content-shoppin{
		position:fixed; 
		bottom:10%; 
		position:absolute;
		vertical-align: bottom;
		width: 30%;
	}
	.content-smartphone{
		top: 40%;
		left : 53%;
		width: 30%;
	}
	.content-description-ellipse{
		top: 45%;
		left: 10%;
	}
	
		
}


