.footer{
	background-color: #C7EAF2;
	padding: 10px;
	display: flex;
}

.nameLogo{
	font-size: 32px;
	color: #33B5E5;
	font-weight: bold;
	margin-top: 5px;
}

.service{ 
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 20px;
	text-align: center;
}

.service p{
	font-size: 16px;
	height: 50px;
}

.service h3{
	font-weight: 600;
	font-size: 25px;
}

.column{
	display: flex;
	flex-direction: column;
}

.btn-services{
	border-radius: 5px;
	width: 250px;
	background-color: #33B5E5;
	padding: 10px;
	border: none;
}

.social{
	display: none;
}

.first-column{
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	text-align: center;
}

.RSStyle{
	margin: 20px 0;
	display: flex;
	align-items: center;
	gap: 15px;
}

.first-column h2{
	font-size: 20px;
	line-height: 24px;
	font-weight: 700;
	margin-top: 5px;
}

.fw-bold{
	font-size: 16px;
	font-weight: 600;
	text-align: left;
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 800px) {
	.social{
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}

	.RSStyle{
		display: none;
	}

	.service{
		margin: 20px 0;
	}
}

@media screen and (max-width: 600px) {

}
