.card-carouselWeb {
		width: 340px !important;
		height: 490px;
		background: #E4E7E9;
		border-radius: 20px;
		margin: 25px;
		border: 5px solid #FFFFFF;
		box-shadow: 0 3px 10px 0 rgb(0 0 0 / 25%); 
		overflow: hidden;

		@media(max-width: 325px){
			margin: 0px;
			width: 274px !important;
			height: 497px;
			background: #E4E7E9;
			border-radius: 20px;
			border: 5px solid #FFFFFF;
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
			overflow: hidden;
		}

		.title-card {
			font-size: 24px;
			font-weight: bold;
			padding-left: 10px;
			padding-right: 10px;
			padding-top: 10px;

			@media(max-width: 325px){
				font-size: 20px;
			}

		}

		.description-card {
			font-size: 16px;
			font-weight: 400;
			padding-left: 10px;
			padding-right: 10px;
			font-family: 'Montserrat';
			font-style: normal;
			line-height: 20px;
			height:127px;

			@media(max-width: 325px){
				font-size: 14px;
				height:100px;
				padding-right: 10px;
				text-align: center;
				padding-left: 10px;


			}
			
		}

	}

	.slick-next:before,
	.slick-prev:before {
		font-size: 24px;
		line-height: 1;
		opacity: .75;
		color: #212121 !important;


		@media(max-width: 768px){
			left: 2px;
			right: 2px;
			z-index: 3;
		}
	}

	.slick-next{
		@media(max-width: 768px){
			right: 5px !important;
			z-index: 3;
		}
	}
	.slick-prev{
		@media(max-width: 768px){
			left: 2px !important;
			z-index: 3;
		}
	}

	.wrapper-icons {
		display: flex;
		flex-direction: row;
		justify-content: space-around;

		@media(max-width:320px){
			margin-top: 50px;
		}
	}


	.iconTechnology {
		width: 60px;
	}

	.imgWeb{
		height: 220px;
	}

	.slick-list{
		@media(max-width: 768px){
			text-align-last: center;
		}
	}

	