.container {
	.contenedor {

		.box-color {
			background: rgba(161, 220, 234, 0.6);
			;
			width: 766px;
			height: 379px;
			position: absolute;
			z-index: -1;
			right: 0px;
			margin-top: 133px;
			overflow: hidden;
		}

		.box2-color {
			background: rgba(161, 220, 234, 0.6);
			width: 764px;
			height: 366px;
			position: absolute;
			left: 0px;
			margin-top: 130px;
			overflow: hidden;

		}

		.box3-color {
			background: rgba(161, 220, 234, 0.6);
			width: 850px;
			height: 451px;
			position: absolute;
			right: 0px;
			margin-top: 121px;
			overflow: hidden;

		}

		.title-contenedor {
			display: flex;
			justify-content: center;
			text-align: center;
			margin-top: 26px;
			margin-bottom: 65px;

			.title-portfolio {
				// font-size: 36px;
				// font-family: "Montserrat", sans-serif;
				// font-style: normal;
				margin-top: 60px;

				h1 {
					font-weight: bold;
					text-align: center;
					font-size: 50px;
					background: -webkit-linear-gradient(150deg,
							rgb(152, 141, 243) 0%,
							rgba(71, 87, 224, 1) 46%,
							rgba(0, 212, 255, 1) 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					height: max-content;
				}
			}
		}
		.section-box {
			margin-bottom: 85px;
			width: 100%;
			height: 576px !important;

			.section-title:not(.web-title){
				margin-top: 150px;
			}

			.section-title {
				display: flex;
				justify-content: center;
				margin-bottom: 20px;

				hr {
					position: absolute;
					height: 7px;
					background: -webkit-linear-gradient(150deg,
							rgb(152, 141, 243) 0%,
							rgba(71, 87, 224, 1) 46%,
							rgba(0, 212, 255, 1) 100%);
					width: 28%;
					margin-top: 51px;
				}

				hr:nth-child(1) {
					margin-top: -10px;
				}

				// hr:nth-child(2) {
				//   margin-top: 10px;
				// }

				h4 {
					font-size: 40px;
					color: black;
					font-family: "Montserrat", sans-serif;
					font-style: normal;
					font-weight: 600;
				}
			}
		}
	}
}

@media screen and (max-width: 321px) {

	.container .contenedor .title-contenedor {
		display: flex;
		justify-content: center;
		text-align: center;
		margin-top: -26px;
		margin-bottom: 65px;
	}


	.container {
		.section-box {
			.mobile-box {
				margin-bottom: 637px !important;
			}
		}
	}

	.section-title {
		h4 {
			font-size: 32px !important;
			color: black;
			font-family: "Montserrat", sans-serif;
			font-style: normal;
			font-weight: 600;
		}
	}

	.container {
		.mobile-box {
			.title-mobile {
				font-size: 26px !important;
				color: black;
				font-family: "Montserrat", sans-serif;
				font-style: normal;
				font-weight: 600;
				text-align: center;
				margin-top: 10px !important;
			}
		}
	}


	.container .contenedor {
		.section-box {
			margin-bottom: 85px;
			width: 100%;
			height: 450px !important;
		}
	}


	.box-2 {
		margin-top: 150px !important;
	}
}

@media screen and (max-width: 500px) {
	.container {

		.box-color,
		.box2-color,
		.box3-color {
			display: none;
		}

		// max-width: 400px;
		.contenedor {
			.cols {
				padding-right: 7px !important;
			}

			.title {
				font-size: 28px !important;
			}
		}

		.section-title:not(.web-title) {
			margin-top: 200px !important;
		}

		.section-title {
			h4 {
				font-size: 32px !important;
				color: black;
				font-family: "Montserrat", sans-serif;
				font-style: normal;
				font-weight: 600;
			}

		}


		.mobile-box {
			margin-bottom: 200px !important;


			hr {
				width: 81% !important;
			}

			.title-mobile {
				font-size: 26px;
			}
		}

		.box-2 {
			margin-top: 219px;
		}

		.section-box:not(.mobile-box) {
			margin-bottom: 100px !important;

			hr {
				width: 81% !important;
			}

			.title-mobile {
				font-size: 33px !important;
			}

		}
	}
}

@media screen and (min-width: 1800px) {
	.background-box {
		height: 41% !important;
		width: 51% !important;
		position: absolute;
		background: rgba(161, 220, 234, 0.6);
		left: 49% !important;
		top: 64% !important;
		z-index: -1;
	}


	.background-box2 {
		height: 38% !important;
		width: 51%;
		position: absolute;
		background: rgba(161, 220, 234, 0.6);
		right: 49% !important;
		top: 138% !important;
		z-index: -1;
	}



	.background-box3 {
		height: 46% !important;
		width: 62% !important;
		position: absolute;
		background: rgba(161, 220, 234, 0.6);
		left: 38% !important;
		top: 214% !important;
		z-index: -1;
	}
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
	.background-box {
		height: 50% !important;
		width: 51%;
		position: absolute;
		background: rgba(161, 220, 234, 0.6);
		left: 49%;
		top: 80% !important;
		z-index: -1;
	}

	.background-box2 {
		height: 54%;
		width: 62%;
		position: absolute;
		background: rgba(161, 220, 234, 0.6);
		right: 49%;
		top: 170% !important;
		z-index: -1;
	}

	.background-box3 {
		height: 50% !important;
		width: 62%;
		position: absolute;
		background: rgba(161, 220, 234, 0.6);
		left: 38%;
		top: 265% !important;
		z-index: -1;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .background-box {
		height: 109% !important;
		top: 183% !important;
		z-index: -1;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .background-box2 {
		height: 100% !important;
		top: 390% !important;
		z-index: -1;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .background-box3 {
		height: 100% !important;
		top: 600% !important;
		z-index: -1;
	}
}

_::-webkit-full-page-media,
_:future,
:root .background-box {
	height: 45% !important;
	top: 73% !important;
	z-index: -1;
}

_::-webkit-full-page-media,
_:future,
:root .background-box2 {
	height: 45% !important;
	top: 158% !important;
	z-index: -1;
}

_::-webkit-full-page-media,
_:future,
:root .background-box3 {
	height: 45% !important;
	top: 245% !important;
	z-index: -1;
}

@media screen and (min-width: 520px) and (max-width: 1000px) {

	.background-box,
	.background-box2 {
		display: none;
	}

	hr {
		width: 54% !important;
	}

	.box-2 {
		margin-bottom: 150px;
		width: 100%;
		height: 576px !important;
		margin-top: 389px !important;
	}

	.mobile-box {
		margin-bottom: 458px !important;
		width: 100%;
		height: 636px !important;

		.section-title {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			margin-top: 164px;

		}
	}

}