$items: 4;
$transition-duration: 0.5s;
$transition-delay: 0.05s;

.btnTranslate{
    position: absolute;
    top: -10px;
    right: 9px;
    font-size: 18px;
}

.btnContact{
	padding-right: 101px !important;
	// margin-left: 76px;
	
	a:hover{
		text-decoration: none !important;
	}
}

.buttonContact {
	width: 204px !important;
	height: 56px !important;
	background: #00C1FF !important;
	/* Sombra */
	box-shadow: 0px 20px 35px rgba(15, 84, 138, 0.25);
	border-radius: 10px !important;
	border: none;
	margin-bottom: 46px;
	color: white;
	margin-top: -15px;
	text-align: center;
	padding-top: 12px;
	font-weight: 500;
	text-decoration: none;

	span:hover{
		text-decoration: none !important;
		outline: none !important;
		color: white !important;
		
	}
}

.buttonContact:hover{
	text-decoration: none !important;
	outline: none !important;
	color: white !important;
}


 .portfolio {
		height: 78%;
		width: 50%;
		position: absolute;
		background: rgba(161,220,234,.6);
		left: 50%;
		top: 0px;
		z-index: -1;
		
}

.shadow{
	 box-shadow: 5px 10px #888888;;
	
}

.navbar{
	
		width: 100%;
		right: 0;
		left: 0;
		overflow: hidden;
}

// .portfolio{
// 	display: none;
// }


.Home, .nav-Home{
	.ellipseimg, .puntos{
		display: none;
	}
}

.navbar {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	// background-color: transparent;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	position: absolute !important;
	top: 0 !important;
	z-index: 3 !important;

	.back {
		height: 100%;
		width: 678px;
		position: absolute;
		margin-left: 374px;
		background: rgba(161, 220, 234, 0.6);
		z-index: -1;
	}

	.ellipseimg {
		float: right;
	}

	.nav-img-title {
		display: inline-flex;
		margin-left: 20%;
	

		.vinculo-title {
			.nav-title {
				font-size: 25px;
				padding-top: 68px;
				margin-left: -10vmax;
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: bold;
				font-size: 30px;
				line-height: 37px;
				color: #0082AC;
			}
		}
	}

	.navbar-brand {
		display: inline-block;
		padding-top: 0rem;
		padding-bottom: 0.3125rem;
		padding-left: 80px;
		line-height: inherit;
		white-space: nowrap;
	}

	.navbar-nav {
		padding-left: 10%;
		margin-top: 4%;
	}

	.nav-item {
		padding: 0px !important;
		padding-top: 32px !important;
		list-style: none !important;
		padding-right: 40px !important;


		.box-btn {
			margin-left: -6px;
			margin-top: -8%;
		}
	}
	.nav-item:nth-child(1){
		color: #EC7263;
	}

	.btnContact{		
		padding-left: 71px !important;
	}

	.nav-link {
		font-size: 20px;
		padding-top: 9px;
		// padding-bottom: 10px;
		// font-family: 'Archivo Narrow', sans-serif;
		color: white !important;
	}
	.about{
		width: 159px !important;
	}

	.about:hover,.about:active{
		width: 159px !important;
	}
}

.scrolling-navbar {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

@media only screen and (max-width: 1024px) {
	.navbar {
		.navbar-collapse {
			.navbar-nav {
				.nav-item {
					.nav-link {
						margin-top: -5px !important;
						font-size: 24px !important;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 700px) {
	.ellipseimg {
		display: none !important;
	}

	.navbar {
		.navbar-brand {
			padding-top: 15px !important;
			position: absolute;
		}

		.navbar-toggler {
			padding-top: 15px !important;

		}

		.navbar-collapse {
			.navbar-nav {
				.nav-item {
					.nav-link {
						margin-left: 140px !important;
						font-size: 35px !important;
					}

				}
			}
		}
	}
}

.textHeader {
	color: #000;
	// -webkit-transition: 35s;
	text-decoration: none;
	font-family: 'Montserrat';
	padding: 0px !important;
	font-size: 1.0em;
	font-weight: 500;
	width: 128px;
	
}

.textHeader:hover,
.textHeader:focus {
	text-decoration: none;
	font-size: 20px;
	color: #0082ac !important;
	font-weight: bold;
}

.dot{
	width: 10px;
	height: 10px;
	background: #0082ac;
	border-radius: 50%;
	-webkit-transform: translateX(30px);
	transform: translateX(30px);
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	opacity: 0;
  }

  .dot:hover, .dot:focus{
	width: 10px;
	height: 10px;
	background: #0082ac;
	border-radius: 50%;
  }


  .textHeader:hover > .dot{
	  opacity: 1;
  }

.button {
	border-radius: 19px !important;
}

@media screen and (min-width:1900px) {
	.navbar {
		.back {
			height: 100%;
			width: 52.8%;
			position: absolute;
			margin-left: 31.45%;
			background: rgba(161, 220, 234, 0.6);
		}

		.portfolio {
			height: 100%;
			width: 54%;
			position: absolute;
			background: rgba(161, 220, 234, 0.6);
			left: 46% !important;
			top: 0px;
			z-index: -1;
		}

		.btnContact{
			padding-left: 243px !important;
			padding-right: 0px !important;
		}

		 .navbar-nav {
			padding-left: 25% !important;
			margin-top: 2%;
		}

		.navbar-brand{
			padding-left: 100px !important;
		}

		.nav-img-title {
			margin-left: 20%;
		}

	}
}

@media screen and (max-width: 500px) {

	.btnContact{
		margin-left: -37px !important;
		margin-top: 18px !important;
	}

	.portfolio{
		display: none;
	}

	.navbar-toggler-icon {
		position: absolute;
		display: inline-block;
		width: 40px;
		height: 40px;
		margin: 25px;
		background-color: transparent;
		border: none;
		cursor: pointer;
		border-radius: 100%;
		transition: $transition-duration + 0.1;
	  
		// Shadow on Hover
		&:hover {
		 box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
		}
		&:before, &:after {
		  position: absolute;
		  content: '';
		  top: 50%;
		  left: 0;
		  width: 100%;
		  height: 2px;
		  background-color: #EC7263;
		  border-radius: 5px;
		  transition: $transition-duration;
		}
	  
		&:before {
		  transform: translateY(-50%) rotate(45deg) scale(0);
		}
	  
		&:after {
		  transform: translateY(50%) rotate(-45deg) scale(0);
		}
	  }

	.box-btn {
		margin-top: -1% !important;
	}

	.navbar {
		padding:50px !important;

		.navbar-brand {
			margin-left: -135px !important;
		}

		.navbar-toggler {
			/* padding-top: 15px !important; */
			/* margin-left: 25% !important; */
			position: relative;
			right: 30px;
			bottom: 103px !important;
		}

		.vinculo-title {
			margin-left: -7vmax !important;
		}


		.navbar-collapse {
			padding-bottom: 32px;
			background: white;
			margin-right: -12px;
			margin-left: -16px;
			margin-top: -101px;

			.navbar-nav {
				padding-bottom: 32px;
				background: white;
				margin-right: -12px;
				margin-left: -16px;
				margin-top: -16px;
				.nav-item {


					.nav-link {
						// text-align: start;
						margin-left: 12px !important;
						font-size: 20px !important;
					}

					.box-btn {
						padding-left: 10% !important;
					}
				}

				.nav-item:nth-child(1){
						margin-left: -11px !important;
					
				}
			}
		}
	}
}

@media only screen and (min-width:1024px) and (max-width:1400px) {

	.nav-img-title {
		margin-left: 20%;
	}
}

@media only screen and (min-width: 500px) and (max-width: 900px){
	.portfolio{
		display: none;
	}

	.navbar-brand{
		margin-right: 19rem !important;
		padding-left: 0px !important;
	}

	.nav-title{
		margin-left: -19vmax !important;
	}

	.navbar-collapse{
		padding-bottom: 32px;
		background: white;
		margin-right: -12px;
		margin-left: -16px;
		margin-top: 25px;
	}

	.hr1, .hr2{
		display: none;
	}

}

@media only screen and (min-width: 1440px) and (max-width: 1800px){

	.navbar .nav-item .box-btn {
		// margin-left: 190px !important;
		position: absolute;
		margin-top: -2%;
	}

	
	.navbar-brand {
		display: inline-block;
		padding-top: 0rem;
		padding-bottom: 0.3125rem;
		padding-left: 160px;
		line-height: inherit;
		white-space: nowrap;
	}

	.btnContact{
		margin-left: 76px;
	}

	.portfolio {
		height: 100%;
		width: 50%;
		position: absolute;
		/* margin-left: 57.45%; */
		background: rgba(161, 220, 234, 0.6);
	}
	
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
	.box-btn {
		margin-left: -20px !important;
		margin-top: 1% !important;
	}
}

@media only screen and (max-width: 350px) {
	 .nav-title {
		font-size: 25px;
		padding-top: 68px;
		margin-left: -16vmax !important;
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 37px;
		color: #0082AC;
	}
}

@media only screen and (min-width:992px) and (max-width:1200px){

	.nav-img-title{
			margin-left: 73px;
	}

	.nav-title{
		margin-left: -14vmax !important;
	}
}

@media only screen and (min-width: 1024px) and (max-width:1200px){
	.navbar-brand {
		display: inline-block;
		padding-top: 0rem;
		padding-bottom: 0.3125rem;
		padding-left: 52px;
		line-height: inherit;
		white-space: nowrap;
	}

	.btnContact{
		padding-left: 30px !important;
	}
	
	.navbar .navbar-nav {
		padding-left: 5%;
		margin-top: 4%;
	}

	.navbar .nav-item {
		padding: 0px !important;
		padding-top: 33px !important;
		list-style: none !important;
		margin-left: 6px !important;
	}

	.navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
		margin-top: -5px !important;
		font-size: 20px !important;
	}

}

@media only screen and (min-width: 700px) and (max-width:1024px){
.navbar-brand {
    display: inline-block;
    padding-top: 0rem;
    padding-bottom: 0.3125rem;
    padding-left: 32px !important;
    line-height: inherit;
    white-space: nowrap;
}
	.navbar-toggler{
		margin-right: 43px;
		padding-bottom: 32px;
			background: white;
			margin-right: -12px;
			margin-left: -16px;
			margin-top: -20px;
	}

	.navbar-nav{
		padding-bottom: 32px;
		background: white;
		margin-right: -12px;
		margin-left: -16px;
		margin-top: 0% !important;
	}

	.btnContact{
		margin-left: -60px !important;
		margin-top: 18px !important;
	}
}



@media only screen and (min-width: 700px) and (max-width:1024px){
	.navbar .navbar-nav {
		padding-left: 15%;
		/* margin-top: 4%; */
	}

	.btnTranslate {
		position: absolute;
		top: 3px;
		right: 1 !important;
		font-size: 18px;
	}

	.nav-link{
		font-size: 24px !important;
	}
}

@media screen and (max-width:1024px) {
	.btnTranslate {
		position: absolute;
		top: 3px;
		right: 1 !important;
		font-size: 18px;
	}

}

@media screen and (max-width:1023px) {
	.nav-1440{
		display: none !important;
	}
}

.tabs{
    width: 32%;
    /* margin: 0 auto 2rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    align-content: center;
    position: relative;
    margin: auto;

}
.tabsPortfolio{
    width: 32%;
    /* margin: 0 auto 2rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    align-content: center;
    position: relative;
    margin: auto;

}

.tabs > span > a{
	font-size: 20px;
	color: #000 !important;
	font-weight: 400;
}

.tabsPortfolio > span > a{
	font-size: 20px;
	color: black !important;
	font-weight: 400;
}
.tabs > span > a:hover{
	text-decoration: none !important;
	border-bottom: 2px solid blue;

}

.navBar-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: center;
    width: 100%;
    position: absolute !important;
    top: 20px !important;
    z-index: 3 !important;
    padding-left: 100px;
    padding-right: 100px;

	@media (max-width:1000px){
		display: none;
	}
}

.Btns{
	display: flex;
	flex-direction: row;
}

.Btns > button, .Btns > a{
    width: 121px !important;
    height: 48px !important;
    background: #542fb8 !important;
    box-shadow: 0px 20px 35px rgba(15, 84, 138, 0.25);
    border-radius: 10px;
    border: 1px solid white;
    color: white;
    text-align: center;
    font-weight: 400;
    text-decoration: none;
	margin-right: 10px;
}

.Btns > button:hover{
	
}

.Btns > a{
	padding-top: 4%;
text-decoration: none !important;
}
.Btns > a:hover{
text-decoration: none !important;
color: aliceblue;
}

.logoNav{
	margin-right: 140px;
}