.container {
	.title-container {
		display: flex;
		justify-content: center;

		h1 {
			font-family: "Montserrat";
			font-style: normal;
			font-weight: bold;
			font-size: 55px;
			line-height: 38px;
			color: black !important;
			padding-bottom: 44px;
			text-align: center;
			padding-top: 80px;
		}
	}

	.countrys{
		display: flex;
		align-items: center;
		justify-content: center;

		img{
			width: 110%;
		}

	}

}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 800px) {
	.countrys{
		flex-direction: column;
	}
}

@media screen and (max-width: 600px) {
	.countrys{
		flex-direction: column;
	}
}