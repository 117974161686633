.modalBack {
    width: 100vw;
    height: 113vh;
    position: fixed;
    background-color: rgba(200, 200, 200, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    z-index: 99999;
    top: 0;
    float: initial;

	@media screen and (max-width:786px) {
		width: 100vw;
		height: 100vh;
		position: fixed;
		background-color: rgba(200, 200, 200, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		right: 0;
		z-index: 99999 !important;
		top: 0 !important;
	}
}

.ContainerModal {
	width: 72vw;
	padding-bottom: 100px;
	background-color: #fff;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding: 61px;
	overflow-y: auto;
	position: relative;
	height: 100%;
	overflow: hidden;
	position: absolute;
	overflow-y: scroll;
	scroll-behavior: smooth;
}

.btnClose {
	z-index: 2;
	background: #ffffff;
	border: none;
	font-size: 25px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	width: 40px;
	height: 39px;
	/* right: 0; */
	margin-left: auto;
	position: relative;
	border-radius: 4px;
	top: -46px;
	box-shadow: 3px 3px 4px 1px #e0e0e085;
	left: 22px;
	align-items: center;

}

.btnClose:focus, .btnClose:focus-visible{
    border: none !important;
    outline: none;
}

.recaptcha {
	display: flex;
	align-content: center;
	justify-content: center;
	margin-top: 20px;
}


.openModal {
	width: 50px;
	height: 100px;
	background: #1766dc;
}

.titleModal {
	font-weight: bold;
	font-size: 40px;
	font-family: 'Montserrat';
	margin-left: auto;
}

hr {
	width: 30vw;
	border: 1px solid #CBCBCB;
}

.Field {
	background: #F8F7F7;
	border: 1px solid #C5C5C5;
	border-radius: 10px;
	padding-bottom: 12px;
	margin-bottom: 20px !important;
	padding-top: 10px;
	padding-left: 21px;
	width: 20vw;
	margin-right: 15px;
}

.commentsArea {
	border-radius: 10px;
	width: 60vw;
	padding-left: 10px;
}


.instruction2 {
	font-weight: bold !important;
	margin-top: 0px !important;
	margin-bottom: 20px !important;
	font-size: 18px;
	text-align: center;
	margin-right: 20px;
}


.headerModal {
	display: flex;
	flex-direction: row-reverse;
}

.webItems {
	display: flex;
	flex-direction: column;
}

.checkboxs {
	margin-right: 6px;
}

label {
	padding-left: 5px;
	padding-right: 6px;
}

.formContact {
	display: flex;
	flex-direction: row;
	justify-content: center;

	@media (max-width:786px) {
		flex-direction: column;
	}
}

.btnSend {
	border: none;
	background: #00C1FF;
	width: 300px;
	height: 56px;
	justify-content: center;
	border-radius: 8px;
	color: #fff;
	font-weight: bold;
	font-family: "Montserrat";
	box-shadow: 0px 20px 35px rgba(15, 84, 138, 0.25);
}

.error {
	color: red;
	margin-top: -18px;
	font-size: 15px;
	margin-left: 9px;
}

.textForm {
	font-weight: 600;
	font-size: 16px;
}

.boxModal {
	width: 120px;
	height: 100px;
	background: #C7EAF2;
	position: absolute;
	right: -19px;
	top: -27px;
	z-index: 2;
	transform: matrix(0.93, -0.36, 0.35, 0.93, 0, 0);
	border-radius: 8px;
}

.sphereh {
	position: absolute;
	left: 0;
	top: 0;
	width: 60px;
	height: 60px;
}

.checkbox {
	display: inline-table;
}

.checkbox label {
	display: inline-block;
	cursor: pointer;
	color: #26303b;
	position: relative;
	padding: 5px 15px;
	font-size: 1em;
	border-radius: 57px;
	background: rgba(15, 108, 108, 0.15);
	transition: all 0.3s ease;
	margin-right: 10px;
}

.checkbox label:hover {
	background: rgba(255, 65, 54, 0.1);
}

.checkbox label:before {
	content: "";
	display: inline-block;
	width: 17px;
	height: 17px;
	position: absolute;
	left: 15px;
	border-radius: 50%;
	background: none;
}

.checkbox label:before {
	border-radius: 3px;
}

.checkbox input[type="checkbox"] {
	display: none;
}

.checkbox input[type="checkbox"]:checked+label:before {
	display: none;
}

.checkbox input[type="checkbox"]:checked+label {
	background: #0E82AA;
	color: #fff;
	padding: 5px 15px;
}

.selectedOption {
	border: 1px solid #d5dfea;
	width: 30vw;
	margin-bottom: 19px;
	padding-bottom: 19px;
	padding-left: 14px;
	padding-top: 15px;
}

.valuesTxt {
	margin-right: 11px;
	display: inline-block;
	background: #83ddff36;
	border-radius: 21px;
	padding: 5px 17px 7px;
	color: black;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 12px;
}

button:disabled {
	background: grey;
}

.instruction {
	font-size: 18px;
	margin-top: 0px !important;
}

@media screen and (max-width:768px) {
	.ContainerModal {
		width: 89vw;
		padding-bottom: 100px;
		background-color: #fff;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		padding: 21px;
		overflow-y: auto;
		position: relative;
		height: 100%;
		overflow: hidden;
		position: absolute;
		overflow-y: scroll;
		scroll-behavior: smooth;
		// right: 11px;
	}

	.Field {
		background: #F8F7F7;
		border: 1px solid #C5C5C5;
		border-radius: 10px;
		padding-bottom: 12px;
		margin-bottom: 20px !important;
		padding-top: 10px;
		padding-left: 21px;
		width: 75vw;
	}

	.selectedOption {
		width: 75vw !important;
	}

	.titleModal {
		z-index: 2;
		font-size: 32px;
		margin-top: 40px;
		margin-left: 40px !important;
	}

	.commentsArea {
		padding-bottom: 124px;
		border-radius: 10px;
		width: 75vw;
	}



	.btnBtm {
		display: flex;
		align-items: center;
		margin-top: 41px;
		flex-direction: row;
		flex-wrap: nowrap;
		align-content: space-between;
		justify-content: center;
	}

	.btnClose {
		z-index: 2;
		background: #ffffff;
		border: none;
		font-size: 25px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		width: 40px;
		height: 39px;
		/* right: 0; */
		margin-left: auto;
		position: relative;
		border-radius: 4px;
		top: -6px;
		box-shadow: 3px 3px 4px 1px #e0e0e085;
		left: 15px;
		align-items: center;
	}

	.instruction2 {
		font-weight: bold !important;
		margin-top: 0px !important;
		margin-bottom: 20px !important;
		font-size: 16px;
	}

	.btnCancel {
		align-self: center !important;
		margin-top: 18px !important;
		font-size: 18px !important;
		color: rgb(0, 157, 255) !important;
		font-weight: bold !important;
		text-decoration-line: underline !important;
	}

	.alert {
		background: white;
		width: 78vw !important;
		height: 32vh;
		box-shadow: 5px 4px 8px 2px #707070;
		display: grid;
		justify-content: center;
		border-radius: 15px !important;
		justify-items: center;
		padding-top: 36px !important;
	}

	.linea {
		width: 78vw !important;
		background: #009dff;
		height: 14px;
		margin-top: -1px;
		position: absolute;
		z-index: 1;
		border-radius: 15px 15px 0px 0px;
	}
}

.btnBtm {
	display: flex;
	justify-content: center;
	align-items: stretch;
	margin-top: 41px;
	flex-direction: column;
	flex-wrap: wrap;
	align-content: center;
}

.btnCancel {
	align-self: center !important;
	margin-top: 39px;
	font-size: 18px !important;
	color: rgb(0, 157, 255) !important;
	font-weight: bold !important;
	text-decoration-line: underline !important;
}



@media screen and (min-width:1800px) {
	.btnBtm {
		display: flex;
		justify-content: space-evenly;
		align-items: stretch;
		margin-top: 41px;
	}

	.btnCancel {
		align-self: center !important;
		margin-top: 39px;
		font-size: 18px;
		color: rgb(0, 157, 255) !important;
		font-weight: bold;
		text-decoration-line: underline;
	}
}


.modalAlertaBack {
	width: 100vw;
	height: 100vh;
	position: fixed;
	background-color: rgba(93, 93, 93, 0.72);
	display: flex;
	align-items: center;
	justify-content: center;
	right: 0;
	z-index: 3;
	top: 0;
}

.alert {
    background: white;
    width: 30vw;
    height: 43vh;
    box-shadow: 5px 4px 8px 2px #707070;
	display: grid;
    justify-content: center;
	border-radius: 15px !important;
	justify-items: center;
	padding-top: 36px !important;
}

.textMdlAlert {
    font-size: 20px;
    font-weight: 600;
	text-align-last: center;
    text-align: center;
}

.closeModal{
		text-decoration: none;
		background: rgb(0, 157, 255);
		width: 90px;
		text-align: center;
		height: 31px;
		border-radius: 18px;
		color: white;
		padding-top: 3px;
}

.closeModal:hover{
		color: rgb(0, 157, 255);;
		text-decoration: none;
		background: white;
		border: 1px solid rgb(0, 157, 255);
		font-weight: 700;

}

.linea {
    width: 30vw;
    background: rgb(0, 157, 255);
    height: 14px;
    margin-top: -1px;
    position: absolute;
    z-index: 1;
    border-radius: 15px 15px 0px 0px;
}