.containerLoading {
    display: flex;
    width: 100%;
    height: 100vh;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loadingImg {
    position: relative;
    animation: rotate-scale-up 3s infinite both;
}
@keyframes rotate-scale-up {
    0% {
      transform: scale(1) rotateZ(0);
    }
    50% {
      transform: scale(2) rotateZ(180deg);
    }
    100% {
      transform: scale(1) rotateZ(360deg);
    }
  }
  
  .loadingText{
        margin-top: 29px !important;
        font-size: x-large;
        font-weight: 300;
  }