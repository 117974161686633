.btnUp{
    position: absolute;
    right: 15px;
    margin-top: -367px;
}

.btnStyle{
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: #6c92a1;
    border-radius: 100px;
    border: 1px #ebebeb solid;;
    box-shadow: 10px 12px 38px 0px #a1a1a1;
    position: relative;
    z-index: 4;
}

.btnStyle:focus, .btnStyle:focus-visible{
    border: none !important;
    outline: none;
}