.not-found {
    /* position: absolute; */
    margin-top: 20%;
    /* display: flex; */
    text-align: center;
    margin-bottom: 20%;

    .code {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 288px;
        line-height: 337px;
        background: linear-gradient(90.13deg, #0182AC -7.14%, #00C1FF 48.58%, #00FFFF 109.3%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: -4rem;
        filter: drop-shadow(2px 0px 8px rgba(0, 0, 0, 0.24));
    }

    .message {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 144px;
        line-height: 169px;
        text-transform: uppercase;
        background: linear-gradient(90.13deg, #0182AC -7.14%, #00C1FF 48.58%, #00FFFF 109.3%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(2px 0px 8px rgba(0, 0, 0, 0.24));
    }

    .messages {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 300;
        font-size: 36px;
        line-height: 42px;
    }

    a {
        background: #00C1FF;
        /* Sombra */
        width: 302px;
        height: 68px;

        box-shadow: 0px 20px 35px rgba(15, 84, 138, 0.25);
        border-radius: 40px 3px 40px 0px;
        border: none !important;
        margin-inline: auto;
        margin-top: 3%;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 40px;
        color: white;
        padding: 10px;
    }

    .Sphere3 {
        top: 44%;
        position: absolute;
        left: 41%;
    }

    .Sphere10 {
        top: 71%;
        position: absolute;
        left: 88%;
    }

    .SphereH3 {
        top: 34%;
        position: absolute;
        left: 71%;
    }

    .SphereH31 {
        position: absolute;
        left: 10%;
        top: 105%;
    }

    .Sphere2 {
        position: absolute;
        right: 10%;
        top: 111%;
    }

    .Sphere12 {
        position: absolute;
        margin-left: 5%;
        margin-top: 10%;
    }

}

.Sphere20 {
    position: absolute;
    margin-left: 23%;
    margin-top: -11%;
}

.wave-not {
    float: right;
    margin-top: 22%;
}

.wave-not2 {
    float: left;
    margin-top: -21%;
}

@media screen and (max-width:500px) {
    .code{
        font-size: 140px !important;
            padding-right: 82px;
            margin-left: -32px;
    }

    .message{
            position: absolute !important;
            top: 41% !important;
            left: 4% !important;
            font-size: 50px !important;
    }

    .messages{
        font-size: 25px !important;
        margin-left: -24px !important;
    }

    .go-back{
        margin-left: -24px !important;
    }

    .wave-not{
        display: none;
    }

    .Sphere10, .Sphere2{
        display: none;
    }


}