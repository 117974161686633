.container {

    .content-team {
        margin-top: 180px;
        text-align: center;

        h1 {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: bold;
            font-size: 55px;
            line-height: 50px;
            color: black !important;
            padding-bottom: 44px;
            text-align: center;
        }

        .photo{
            width: 100%;
        }

        .group-photo {
            // position: absolute;
            width: 100%;
        }

        .group{
                margin-top: -67px;
                position: absolute;
                left: 3%;
                z-index: -1;
        }

        
        .group2{
            margin-top: -67px;
            position: absolute;
            right: 3%;
            z-index: -1;
    }
    }

}


@media only screen and (min-width:1800px) {

    .group2{
        right: 14% !important;
    }

    .group{
        left: 14% !important;
    }
    
}