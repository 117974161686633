.aboutUs-container {
	// background-color: #e5e5e5 !important;
}

@media only screen and (max-width:992px) {
	.aboutUs-container{
		overflow: hidden;
			display: grid;
			align-items: center;
			justify-content: center;

	}
}
