header{
	width: 100% !important;
	height: 100% !important;
}

nav{
	height: 164px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo{
	// width: 140px;
	margin-left: 20px;
}

.bar-btn {
    font-size: 25px;
    color: black;
    cursor: pointer;
    right: 27px;
    position: inherit;
	background: white;
	border: none;
}

.divMenu{
	position: absolute;
    z-index: 5;
    background: azure;
    width: 100%;
    left: 0;
    height: 100vh;
    text-align-last: center;

}

.navMenu{
	display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: center;
	transition: 0.5s;
	padding: 0;
}

.navMenu li{
	display: flex;
	line-height: 30px;
	margin: 30px 0;
	transition: all .3s;

}

.navMenu li a{
	color: rgb(0, 0, 0);
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 600;
}

.navMenu li a.active, .nav-menu li a:hover{
	color: rgb(8, 200, 248);
	transition: .3s;
}

.btnContactUs {
	height: 60px;
	width: 254px;
	border-radius: 5px;
	border: none;
	margin-right: 50px;
	padding: 14px;
	background: #00c1ff;
	font-size: 18px;
	font-weight: 600;
	color: white;
	margin-inline: auto !important;
}
.btnQuote {
	height: 60px;
	width: 254px;
	border-radius: 5px;
	border: none;
	margin-right: 50px;
	padding: 14px;
	background: #A294FC;
	font-size: 18px;
	font-weight: 600;
	color: white !important;
	margin-inline: auto !important;
	margin-bottom: 50px;
	margin-top: 20px;

}

.nav-menu .btnContactUs{
	display: none;
	line-height: 30px;
	margin: 50px 0;
	transition: all .5s;
}

.btnContactUs2{
	display: none;
}

.nav2{
	display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px;
}

.btnCloseModal{
	z-index: 2;
	background: #ffffff;
	border: none;
	font-size: 25px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	width: 45px;
	height: 45px;
	/* right: 0; */
	margin-left: auto;
	position: relative;
	border-radius: 4px;
	box-shadow: 3px 3px 4px 1px #e0e0e085;
	align-items: center;
}

@media screen and (min-width:1024px) {

	.nav2{
		display: none !important;
	}

}
