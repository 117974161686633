.portfolio-container {
  .section {
    padding-top: 103px;
    margin-bottom: 50px;

    @media  (max-width:768px) {
      padding-top: 0px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .portfolio-container {
    .section {
      margin-bottom: 50px;
    }
  }
}
